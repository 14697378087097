import Logo from '../assets/images/logo2.png';
import React, { useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const Header = () => {
  useEffect(() => {
    $(function () {
      // Append dropdown trigger icon
      $('.primary-menu').find('li a').each(function () {
        if ($(this).next().length > 0) {
          $(this).parent('li').append('<span class="menu-trigger"><i class="fal fa-angle-down"></i></span>');
        }
      });

      // Expand dropdown on trigger click
      $('.primary-menu').on('click', 'li .menu-trigger', function (e) {
        e.preventDefault();
        $(this).toggleClass('open').parent('li').children('ul').stop(true, true).slideToggle(350);
        $(this).find("i").toggleClass("fa-angle-up fa-angle-down");
      });

      // Add or remove mobile class on window resize
      function breakpointCheck() {
        const windoWidth = window.innerWidth;
        if (windoWidth <= 991) {
          $('.header-navbar').addClass('mobile-menu');
        } else {
          $('.header-navbar').removeClass('mobile-menu');
        }
      }

      breakpointCheck();
      $(window).on('resize', breakpointCheck);

      // Toggle mobile menu
      $('.nav-toggler').on('click', function (e) {
        e.preventDefault();
        $('.site-navbar').toggleClass('menu-on');
      });

      // Close mobile menu
      $('.nav-close').on('click', function (e) {
        e.preventDefault();
        $('.site-navbar').removeClass('menu-on');
      });

      // Offcanvas info menu toggle
      $('.offcanvas-icon').on('click', function (e) {
        e.preventDefault();
        $('.offcanvas-info').toggleClass('offcanvas-on');
      });

      // Close offcanvas info
      $('.info-close').on('click', function (e) {
        e.preventDefault();
        $('.offcanvas-info').removeClass('offcanvas-on');
      });

      // Search box toggle
      $('.header_search_btn > a').on('click', function () {
        $('.page_search_box').addClass('active');
      });

      $('.search_close > i').on('click', function () {
        $('.page_search_box').removeClass('active');
      });

      // Sticky header on scroll
      $(window).on('scroll', function () {
        const window_top = $(window).scrollTop() + 1;
        if (window_top > 50) {
          $('.fixed-btm-top').addClass('reveal');
          $('.navbar-sticky').addClass('menu_fixed animated fadeInDown');
        } else {
          $('.fixed-btm-top').removeClass('reveal');
          $('.navbar-sticky').removeClass('menu_fixed animated fadeInDown');
        }
      });
    });
  }, []);

  return (
    <header className="header-style-2" id="top-header">
      <div className="header-navbar menu-2 navbar-sticky">
        <div className="container-fluid container-padding">
          <div className="d-flex align-items-center justify-content-between">
            <div className="site-logo">
              <Link to="/home">
                <img src={Logo} alt="Abia Tech Hub Logo" className="img-fluid" />
              </Link>
            </div>

            {/* Mobile nav toggler */}
            <div className="offcanvas-icon d-block d-lg-none">
              <a href="#" className="nav-toggler">
                <i className="fal fa-bars"></i>
              </a>
            </div>

            {/* Main navigation */}
            <nav className="site-navbar ms-auto">
              <ul className="primary-menu">
                <li className="current">
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/courses">Courses</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>

              {/* Close icon for mobile menu */}
              <a href="#" className="nav-close">
                <i className="fal fa-times"></i>
              </a>
            </nav>

            {/* Register button */}
            <div className="header-btn border-left-0 ms-3 d-none d-lg-block">
              <Link to="/register" className="btn btn-grey-outline btn-sm-2 rounded">
                <i className="fal fa-user me-2"></i>Register now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
