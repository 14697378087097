import {Link} from 'react-router-dom'
import {getCourses} from '../data/course-data';
const Courses = () => {
    let courses = getCourses();
    return(
        <div>
        <section className="page-header">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-8">
                        <div className="title-block">
                            <h1>Explore Our Courses</h1>
                        </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className="section-padding page" >
            <div className="course-top-wrap mb-100">
                <div className="container">
                    <div className="row align-items-center">
    
                        <div className="col-lg-4">
                            <div className="topbar-search">
                                <form method="get" action="#">
                                    <input type="text"  placeholder="Search our courses" className="form-control" />
                                    <label><i className="fa fa-search"></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="container">
                <div className="row">
                {courses.map(
                    course => (
                        <div key={course.slug} className="course-item col-lg-3 col-md-6 col-sm-6">
                    <div className=" course-style-5 bg-white">
                        <div className="course-header">
                            <div className="course-thumb">
                                <img src={course.img} alt={course.alt} className="img-fluid" />
                                {/* <div className="course-price">{course.fee}</div> */}
                            </div>
                        </div>
    
                        <div className="course-content">
                            <div className="course-meta meta-style-1">
                                <span className="lessons"><i className="far fa-play-circle me-2"></i>{course.curriculum.length} Courses of study</span>
                                <span className="label">Beginner</span>
                            </div>
                            <h4> <Link to={`/courses/${course.slug}`} key={course.slug}>{course.name}</Link></h4>
                        
                            <div className="course-footer mt-20 d-flex align-items-center justify-content-between">
                               <span className="students"><i className="far fa-user-alt me-2"></i></span>
                               <Link to={`/courses/${course.slug}`} key={course.slug} className="rounded-btn"><i className="fa fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                    )
                )}
                

                </div>
            </div>
        </section>
        </div>
    )
}

export default Courses;