import { getCourses } from '../data/course-data';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
const Home = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []); 
    let Acourses = getCourses();
    let num = 4;
    let courses = Acourses.slice(0, num);
    return(
        //banner
    <div>
        <section className="banner-style-4 banner-padding">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-12 col-xl-6 col-lg-6">
                        <div className="banner-content ">
                            <span className="subheading"></span>
                            <h1>Learn a tech skill in {currentYear}</h1>
                            <p className="mb-40"> At Abia Tech Hub Academy, we provide you with the skillsets for careers that power the world</p>
                            <div className="btn-container">
                                <Link to="register" className="btn btn-white rounded ms-2">Get started </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-xl-6 col-lg-6">
                        <div className="banner-img-round mt-5 mt-lg-0 ps-5">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="features-2">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                        <div className="feature-item feature-style-top mb-4 mb-lg-0">
                            <div className="feature-icon">
                                <i className="flaticon-teacher"></i>
                            </div>
                            <div className="feature-text">
                                <h4>Expert Teacher</h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                        <div className="feature-item feature-style-top mb-4 mb-lg-0">
                            <div className="feature-icon">
                                <i className="flaticon-layer"></i>
                            </div>
                            <div className="feature-text">
                                <h4>Self Development</h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                        <div className="feature-item feature-style-top mb-4 mb-lg-0">
                            <div className="feature-icon">
                                <i className="flaticon-education"></i>
                            </div>
                            <div className="feature-text">
                                <h4>Conducive Environment</h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                        <div className="feature-item feature-style-top">
                            <div className="feature-icon">
                                <i className="flaticon-video-camera"></i>
                            </div>
                            <div className="feature-text">
                                <h4>Remote Learning</h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         {/* "Our Partners" section */}
      <section className="our-partners">
        <div className="container">
        <div className="heading text-center mb-40">
        <h2 className="font-lg">Our Partner</h2>
        </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-content">
                <span className="subheading"></span>
                <h3>International Business Machines Corporation (IBM)</h3>
                <p className="mb-40">We are proud to be partnered with International Business Machines Corporation, leading American computer manufacturer, with a major share of the market both in the United States and abroad. Through this partnership, your certificate of completion proudly bears the IBM endorsement, significantly enhancing its value and credibility.</p>
                <div className="btn-container">
                  <a href="https://ibm.com" className="btn partnership-btn" target="_blank" rel="noreferrer">
                    Visit Our Partner
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-img-round mt-3 mt-lg-0 ps-5">
                <img src="assets/IBM1.png" alt="IBMImage" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-partners">
        <div className="container mt-5">
          <div className="row align-items-center justify-content-center">
          <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-img-round mt-3 mt-lg-0 ps-5">
                <img src="assets/3MTT.jpeg" alt="3MTT_LOGO" className="img-fluid" width={"400px"} height={"300px"} />
              </div>
            </div>
            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-content">
                <span className="subheading"></span>
                <h3>3 Million Technical Talent (3MTT)</h3>
                <p className="mb-40">Thrilled to announce our collaboration with the 3 Million Technical Talent (3MTT) programme. Together, we're building Nigeria's tech backbone, empowering our digital economy, and positioning Nigeria as a net talent exporter.</p>
                <div className="btn-container">
                  <a href="https://3mtt.nitda.gov.ng/" className="btn partnership-btn" target="_blank" rel="noreferrer">
                    Visit Our Partner
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="our-partners">
        <div className="container mt-5">
          <div className="row align-items-center justify-content-center">

          <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-content">
                <span className="subheading"></span>
                <h3>Internet Society Foundation</h3>
                <p className="mb-40">We proudly partner with the Internet Society Foundation, supporting <br /> digital inclusion, innovation, and Internet access. <br /> Their sponsorship empowers us to enhance connectivity <br /> and create lasting impact.<br /> We appreciate their invaluable support!</p>
                <div className="btn-container">
                  <a href="https://www.isocfoundation.org/" className="btn partnership-btn" target="_blank" rel="noreferrer">
                    Visit Our Partner
                  </a>
                </div>
              </div>
            </div>
          <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-img-round mt-3 mt-lg-0 ps-5">
                <img src="assets/ath-academy-1.webp" alt="internet Society Organisation" className="img-fluid" width={"400px"} height={"300px"} />
              </div>
            </div>
           
          </div>
        </div>
      </section>

    {/* partner section end */}

        <section className="section-padding course-filter-section" >
        <div className="container">
            <div className="row align-items-center justify-content-between mb-30">
                <div className="col-xl-12">
                    <div className="heading text-center mb-40">
                        <span className="subheading">Course Trending</span>
                        <h2 className="font-lg">Explore popular courses</h2>
                    </div>
                </div>
            </div>
        </div>

        
        
        <div className="container-fluid container-padding">        
            <div className="row course-gallery justify-content-center">
                {
                    courses.map(
                        course => (
                            
                <div className="course-item cat1 cat5 col-lg-3 col-md-6 col-sm-6" key={course.id}>
                <div className=" course-style-5 bg-white">
                    <div className="course-header">
                        <div className="course-thumb">
                            <img src={course.img} alt={course.alt} className="img-fluid" />
                        </div>
                    </div>

                    <div className="course-content">
                        <div className="course-meta meta-style-1">
                            <span className="lessons"><i className="far fa-play-circle me-2"></i>{course.curriculum.length} Courses of study</span>
                            <span className="label">Beginner</span>
                        </div>
                        <h4> <Link to={`/courses/${course.slug}`} key={course.id}>{course.name}</Link> </h4>
                    
                        <div className="course-footer mt-20 d-flex align-items-center justify-content-between">
                            <span className="students"><i className="far fa-user-alt me-2"></i></span>
                            <Link to={`/courses/${course.slug}`} key={course.id} className='rounded-btn'><i className="fa fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
                        )
                    )
                }
                <div className="course-item cat1 cat5 col-lg-3 col-md-6 col-sm-6">
                <div className=" course-style-5 bg-white">

                    <div className="course-content">
                        <div className="course-footer mt-20 d-flex align-items-center justify-content-between">
                            <span className="students"><i className="far fa-more-alt me-2"></i>See More</span>
                            <Link to={'/courses'} className='rounded-btn'><i className="fa fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div> 
            </div>
            </div>
        </div>
        </section>
        <section className="work-process-section">
            <div className="container">
                <div className="row mb-70 justify-content-between">
                    <div className="col-xl-5">
                        <div className="section-heading text-center text-lg-start mb-4 mb-xl-0">
                            <h2 className="font-lg">4 steps start your journey with us</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="step-wrapper">
                            <div className="step-item ">
                                <div className="step-number bg-1">01</div>
                                <div className="step-text">
                                    <h5>Signup with all info</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3  col-lg-6 col-md-6">
                        <div className="step-wrapper">
                            <div className="step-item">
                                <div className="step-number bg-2">02</div>
                                <div className="step-text">
                                    <h5>Make payment</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3  col-lg-6 col-md-6">
                        <div className="step-wrapper">
                            <div className="step-item">
                                <div className="step-number bg-3">03</div>
                                <div className="step-text">
                                    <h5>Attend classes</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3  col-lg-6 col-md-6">
                        <div className="step-wrapper">
                            <div className="step-item">
                                <div className="step-number bg-2">04</div>
                                <div className="step-text">
                                    <h5>Get certified</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    );
}

export default Home;